import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import favicon from "../images/favicon.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/font-awesome.css";
import "../assets/css/animate.min.css";
import "../assets/css/fontello.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import { useStaticQuery, graphql } from "gatsby";



const Layout = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      file(relativePath: { eq: "icon-og.jpg" }) {
        publicURL
      }
    }
  `);

  // Construct the absolute URL for og:image
  const ogImageUrl = `${data.site.siteMetadata.siteUrl}${data.file.publicURL}`;
  return (
    <Fragment>
      <Helmet>
        <meta property="og:title" content={props.pageTitle} />
        <meta
          property="og:description"
          content="We are an Indian NGO dedicated to improving the lives of people in our community through a variety of initiatives"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://pdjjks.org" />
        <meta property="og:image" content={ogImageUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={props.pageTitle} />
        <meta
          name="twitter:description"
          content="We are an Indian NGO dedicated to improving the lives of people in our community through a variety of initiatives"
        />
        <meta name="twitter:image" content={ogImageUrl} />
        <title>{props.pageTitle}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <link rel="icon" href={favicon} />
        <link rel="image_src" href={ogImageUrl} />
      </Helmet>
      {props.children}
    </Fragment>
  );
};
export default Layout;
